import store from "../../store";
import * as api from ".";
import * as AuthActions from "../../store/ducks/auth/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";
import history from "../../config/history";
import { ROUTE } from "../../config/constants";

export async function searchZipcode(zipcode) {
  const data = await api.apiGet("/utils/cep/" + zipcode).then((result) => {
    if (result.error) {
      result.msg.map((m) =>
        store.dispatch(UtilsActions.enqueueSnackbar("warning", m))
      );
      return false;
    } else return result;
  });
  return data;
}

export async function searchCnpj(cnpj) {
  if (cnpj.length === 14) {
    const result = await api.apiGet("utils/receitaws/" + cnpj).then((data) => {
      if (data.error)
        data.msg.map((m) =>
          store.dispatch(UtilsActions.enqueueSnackbar("warning", m))
        );
      else return data;
    });
    return result;
  } else {
    store.dispatch(
      UtilsActions.enqueueSnackbar("info", "Apenas consulta CNPJ!")
    );
  }
}

export async function createClientDoc(dataUpload) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost("/clients/files/create", dataUpload, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACCA1) - Falha ao subir o arquivo!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function createFilesByClientToken(dataUpload) {
  try {
    const data = await api.apiPost(
      "ext/clients/token/files/" + dataUpload.token,
      dataUpload.data
    );
    if (!data.error) return data
    else handleError(data);
  } catch (err) {
    store.dispatch(
      UtilsActions.enqueueSnackbar(
        "error",
        `(#ACCFBCT) - Falha ao subir os arquivos!`
      )
    );
  }
  return { error: true };
}

export async function getClientByToken(token) {
  try {
    const data = await api.apiGet("ext/clients/token/show/" + token);
    if (!data.error) return data;
    else {
      data.msg.map((m) =>
        store.dispatch(UtilsActions.enqueueSnackbar("warning", m))
      );
      return data;
    }
  } catch (err) {
    store.dispatch(
      UtilsActions.enqueueSnackbar(
        "error",
        "(#ACGCBT1) - Falha ao buscar o token!"
      )
    );
  }
}

export async function updateClientByToken(updateData) {
  const data = await api.apiPut(
    "ext/clients/token/update/" + updateData.token,
    updateData
  );
  if (!data.error) return data;
  else {
    return handleError(data);
  }
}

export async function getClients(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/list", {
        params: {
          search: query.search,
          searchSelectedId: query.searchSelectedId,
          branchFilter: query.branchFilter,
          vendorFilter: query.vendorFilter,
          rows: query.rowsPerPage,
          page: query.page,
          column: query.orderColumn,
          orientation: query.orderOrientation,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCS1) - Falha ao buscar os clientes!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getAllClients() {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/get/active", {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCS1) - Falha ao buscar os clientes!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClient(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/show/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGC1) - Falha ao buscar o cliente!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClientContract() {
  const state = store.getState();
  const token = state.auth.token;

  if (token) {
    try {
      const result = await api.apiGet("clients/getLastContract", {
        headers: { olsystem: token },
      });
      if (!result.error) return result;
      else return handleError(result);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGC1) - Falha ao buscar o cliente!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function createClient(createData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost("clients/create", createData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { data, error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACCC1) - Falha ao criar o cliente ${createData.client.name}!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateClient(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "clients/update/" + updateData.id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUC1) - Falha ao atualizar o cliente ${updateData.client.name}!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateClientAccountDueDate(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "clients/accountExpiration/",
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUC2) - Falha ao atualizar vencimentos das contas!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function createClientComment(createData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost("clients/comments/create", createData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACCCC) - Falha ao criar o comentário!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateStatus(id, updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "clients/status/update/" + id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUCS1) - Falha ao atualizar o status do cliente!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function getClientAddressesByClientId(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/addresses/list/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCABCI) - Falha ao buscar os endereços!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClientCommentsByClientId(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/comments/list/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCABCI) - Falha ao buscar os comentários!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClientUsersByClientId(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/users/list/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCUBCI) - Falha ao buscar as pessoas!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClientFilesByClientId(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/files/list/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCFBCI) - Falha ao buscar os arquivos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function updateClientAddress(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "clients/addresses/update/" + updateData.id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUCA) - Falha ao atualizar o endereço do cliente!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function destroyClientComment(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiDelete("clients/comments/destroy/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACDCC) - Falha ao deletar o comentário do cliente!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function destroyClientFile(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiDelete("clients/files/destroy/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACDCF) - Falha ao deletar o arquivo do cliente!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function createClientAddress(createData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost("clients/addresses/create", createData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACCCA) - Falha ao criar o endereço!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function createClientUser(createData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost("clients/users/create", createData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACCCU) - Falha ao criar a pessoa!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateClientUser(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "clients/users/update/" + updateData.id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUCU) - Falha ao atualizar a pessoa!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function toggleToken(id, type) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet(
        "clients/token/toggle/" + id + (type ? "/" + type : ""),
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ACUTT) - Falha ao atualizar o link!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function getContractDocumentsByClientId(clientId) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/contract/document/" + clientId, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ACGCBCI) - Falha ao buscar os documentos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getContractsProductsByClientId(clientId) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/contract/product/" + clientId, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ADGDBCI) - Falha ao buscar os produtos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getProductsByOnlyWithClientId(clientId) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/product/2/" + clientId, {
        headers: { olsystem: token },
      });

      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ADGDBCI) - Falha ao buscar os produtos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getProductsByClientId(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/product/" + query.clientId, {
        params: {
          clientId: query.clientId,
          search: query.search,
          searchSelectedId: query.searchSelectedId,
          // column: query.orderColumn,
          // orientation: query.orderOrientation
        },
        headers: { olsystem: token },
      });

      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ADGDBCI) - Falha ao buscar os produtos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getProductsByClientReport(clientId) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/product/report/" + clientId, {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ADGDBCI) - Falha ao buscar os produtos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClientAddressesReport() {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/city/report", {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ADGCAR) - Falha ao buscar cidades!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getClientAddressesList() {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("clients/addresses/report", {
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#ADGCAL) - Falha ao buscar dados do cliente!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

function handleError(data) {
  if (data.status === 401) store.dispatch(AuthActions.unauthenticated());
  else if (data.status === 403) store.dispatch(AuthActions.unauthorized());
  else
    data.msg.map((m) =>
      store.dispatch(UtilsActions.enqueueSnackbar("error", m))
    );
}
